main {
  &.hok-user-dashboard {
    min-height: calc(100vh - 55px);
  }
}

.hok-user-dashboard {
  @import "dashboard-inputs";

  background: #f5f5f5;

  & > .container-fluid {
    margin-top: 3rem;
    @include media-breakpoint-down(xs) {
      margin-top: 30px;
    }

    @media (max-width: 619.98px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  & > .hok-container {
    margin-top: 3rem;
    @include media-breakpoint-down(xs) {
      margin-top: 30px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 12rem !important;
      padding-right: 12rem !important;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      padding-left: 11rem !important;
      padding-right: 11rem !important;
    }
  }

  & > .hok-container-wide {
    @extend .hok-container;
    @include media-breakpoint-up(lg) {
      padding-left: 7rem !important;
      padding-right: 7rem !important;
    }

    @include media-breakpoint-only(lg) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .no-label-align-helper {
      padding-top: 32px;
    }
  }

  hr {
    background-color: $primary;
  }

  h4 {
    font-size: 22px;
    color: $primary;
    font-weight: bold;
  }

  h3 {
    font-size: 20px;
    color: $primary;
    font-weight: bold;
  }

  p {
    color: $text;
    font-size: 14px;
  }

  .title-text {
    max-width: 1000px;
  }

  .content-text {
    max-width: 1000px;
  }

  .title-text-aligned {
    margin-bottom: 0;
    padding-left: 2rem !important;
  }

  .hok-inline-title {
    @include media-breakpoint-down(xs) {
      flex-basis: 100%;
    }
  }

  .hok-inline-title-filter {
    width: 200px;
  }

  .hok-inline-title-filter-select {
    height: 36px;
  }

  .hok-user-profile-tabs {
    background: none;
    border: none;

    .nav-item {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
      border-radius: 5px 5px 0 0;
      background: $white;

      &:not(:last-child) {
        margin-right: 5px;
      }

      .nav-link {
        border: none;
        border-top: 5px solid $white;
        font-size: 18px;
        color: $primary;
        font-weight: 600;

        &.active,
        &:active {
          border-top: 5px solid $primary;
        }
      }
    }

    .hok-dropdown-tab-full {
      width: 100%;
      border: none;
      border-bottom: 1px solid #cbccce;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        box-shadow: none;
      }

      .nav-link,
      .nav-lik.active,
      .nav-link:active {
        border: none;
        color: $primary;
      }

      .dropdown-menu {
        margin: 0;
        border: none;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        background: $white;
        top: 1px !important;
      }

      .dropdown-item {
        font-size: 16px;
        border: none;
        color: $text;

        &:first-child {
          border-radius: 0;
        }

        &.active,
        &:active {
          background-color: #f0f3f5;
          color: unset;
        }
      }

      .dropdown-divider {
        border-top: 1px solid #707070;
        margin: 8px 20px;
      }
    }
  }

  .hok-card {
    border: none;
    background: none;

    & > .card-body {
      background: $white;
      border-radius: 0 5px 5px 5px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);

      a {
        color: $text;
        font-weight: bold;
      }
    }

    @include media-breakpoint-down(md) {
      background: $white;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);

      .card-body {
        box-shadow: none;
      }
    }
  }

  .hok-my-policies-card {
    & > .card-header {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 50px;
      background-color: $white;
      border-radius: 5px 5px 0 0;

      @include media-breakpoint-down(xs) {
        padding: 0 10px;

        h5 {
          font-size: 18px !important;
        }
      }
    }

    & > .card-body {
      padding: 20px 10px;
      border-radius: 0 0 5px 5px;
      box-shadow: none;

      .hok-card-policy-wrapper {
        display: inline-block;

        .hok-card-policy-link-sm {
          display: block;
          margin: 10px;
        }

        .hok-card-policy-sm {
          width: 140px;
          height: 175px;
          display: flex;
          margin: 0;

          .hok-img-policy-sm {
            height: 80px;
          }
        }

        p:not(.hok-card-counter) {
          font-size: 12px;
        }
      }
    }
  }

  .hok-card-policy-wrapper {
    a {
      text-decoration: none !important;
      display: block;
    }

    .hok-card-policy-link-sm {
      margin: 10px;
      display: none;

      @media (max-width: 1255px) {
        display: block;
      }

      @media (max-width: 406px) {
        margin: 8px;
      }
    }

    .hok-card-policy-link {
      margin: 15px;
      display: none;

      @media (min-width: 1256px) {
        display: block;
      }
    }
  }

  .hok-card-policy {
    @extend .hok-card;
    @media (max-width: 1255px) {
      display: none;
    }
    @media (min-width: 1860px) {
      width: 360px;
    }
    display: flex;
    width: 300px;
    height: 175px;
    margin: 0;

    .card-body {
      border-radius: 3px 3px 3px 3px;
    }

    p {
      line-height: 17px;
      color: $text-dark;
      font-weight: 500;
    }
    img {
      display: block;
      margin: auto;
    }
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  }

  .hok-card-policy::before,
  .hok-card-policy-new::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 5px 10px 0 rgba(141, 141, 141, 0.5);
    opacity: 0;
    transition: opacity 500ms;
  }

  .hok-card-policy:hover::before,
  .hok-card-policy-new:hover::before {
    opacity: 0.8;
  }

  .hok-card-policy-sm {
    display: none;
    @media (max-width: 1255px) {
      display: flex;
    }
    @media (min-width: 407px) {
      width: 159px;
      height: 200px;

      p:not(.hok-card-counter) {
        font-size: 14px;
      }
    }
    margin: 0;
    width: 135px;
    height: 180px;

    .card-body {
      display: flex;
      flex-flow: column;
    }

    p {
      line-height: 15px;
      color: $text-dark;
      font-weight: 500;
    }

    p:not(.hok-card-counter) {
      font-size: 13px;
    }
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  }

  .hok-card-policy-sm::before,
  .hok-card-policy-new-sm::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 2px 6px 0 rgba(141, 141, 141, 0.5);
    opacity: 0;
    transition: opacity 500ms;
  }

  .hok-card-policy-sm:hover::before,
  .hok-card-policy-new-sm:hover::before {
    opacity: 0.8;
  }

  .hok-img-policy-sm {
    width: 50%;
    height: 80px;
    display: block;
    margin: auto;

    @media (min-width: 407px) {
      height: 100px;
    }
  }

  .hok-card-policy-new {
    @extend .hok-card-policy;

    .card-body {
      background: #ed1d24;
      border-radius: 5px 5px 5px 5px;

      p {
        font-size: 18px;
        line-height: 23px;
        color: #fff;
      }
    }
  }
  .hok-card-policy-new-sm {
    @extend .hok-card-policy-sm;
    background: #ed1d24 !important;

    .card-body {
      border-radius: 5px 5px 5px 5px;

      p {
        font-size: 18px;
        line-height: 23px;
        color: #fff;
      }
    }
  }

  .hok-damage-claim-card-header {
    display: flex;

    & > div {
      flex-flow: wrap;
      flex: 1;

      .main-title {
        min-width: 400px;

        @include media-breakpoint-down(xs) {
          min-width: auto;
        }
      }

      .subtitle-text {
        display: block;
        @include media-breakpoint-down(sm) {
          flex-basis: 100%;
          margin-top: 5px;
        }
      }

      .hok-claim-type {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        display: inline-block;
      }
    }

    .hok-claim-mobile-warning {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
        margin: auto 0;
      }
    }
  }

  .hok-card-counter {
    font-size: 22px;
    line-height: 22px !important;
  }

  .card {
    border: none;
    background: $white;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    border-radius: 5px;

    .card-header {
      background: $white;

      .hok-card-heading {
        color: $black;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .card-body {
      a {
        color: $text;
        font-weight: bold;
      }
    }
  }

  .hok-card-search {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;

    .card {
      box-shadow: none;
    }
  }

  .hok-claim-status-checkbox {
    @media (max-width: 400px) {
      width: 100%;
    }
  }

  .hok-support-icon-label {
    @extend .hok-support-icon-label;
  }

  .hok-doc-name {
    word-break: break-word;
    text-overflow: ellipsis;
  }

  .hok-description-text {
    &.indent {
      padding-left: 30px;
      padding-right: 30px;
    }

    p {
      font-size: 18px;
      color: $text-dark;
      margin-bottom: 1.25rem;
    }

    ul {
      font-size: 18px;
      color: $text-dark;
      padding-left: 20px;
      margin-bottom: 1.75rem;
    }

    h5 {
      color: $primary;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 1rem;
      margin-top: 1.75rem;
    }
    .text-underline {
      text-decoration: underline;
    }
  }

  .img-avatar {
    height: 150px;
    width: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 35px;
  }

  label {
    font-size: 16px;
    color: $text;
    font-weight: bold;
    margin-left: 20px;
  }

  .hok-edit-user-name {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .cards-container {
    margin-left: 0.75rem;
    justify-content: flex-start;
    @media (max-width: 660px) {
      margin-left: 0 !important;
    }
    @media (max-width: 554px) {
      justify-content: center;
    }
  }

  @import "dashboard-buttons";

  .hok-no-style-link {
    color: inherit;
    text-decoration: none;
  }

  .hok-news-container {
    width: 377px;
    margin-bottom: 30px;
    display: inline-block;

    @media (max-width: 450px) {
      width: 100%;
      padding: 0 !important;
      display: flex;
      flex-flow: wrap;
      justify-content: center;
    }
  }

  .slick-track {
    height: 225px;
    overflow-y: hidden;
    margin: 0;
  }

  .slick-slide {
    & > div {
      text-align: center;
    }

    padding: 0 16px;

    @media (max-width: 500px) {
      padding: 0 8px !important;
    }

    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      padding-right: 0 !important;
    }
  }

  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }

  .slick-prev::before {
    font-family: FontAwesome; // stylelint-disable-line font-family-no-missing-generic-family-keyword
    content: "\f053";
    color: $text;
    font-size: 28px;
  }

  .slick-next::before {
    font-family: FontAwesome; // stylelint-disable-line font-family-no-missing-generic-family-keyword
    content: "\f054";
    color: $text;
    font-size: 28px;
  }

  .slick-list {
    margin: 0 20px;
    @media (min-width: 490px) {
      margin: 0 35px;
    }
  }

  .hok-news-fixed-width {
    width: 345px !important;
    @media (max-width: 450px) {
      width: 209px !important;
    }

    a {
      text-decoration: none;
    }

    .hok-news-card {
      height: 220px;
      margin-bottom: 5px;
      overflow: hidden;

      .card-body {
        padding-bottom: 10px;
        overflow: hidden;
      }

      .hok-news-card-image {
        width: 100%;
        height: 120px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px 5px 0 0;
      }

      h3 {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: 450px) {
          font-size: 16px;
        }
      }

      .hok-news-subtitle {
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        margin-bottom: 0;
        overflow: hidden;
        // stylelint-disable
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        // stylelint-enable

        @media (max-width: 450px) {
          font-size: 14px;
        }
      }

      .hok-published-at {
        text-align: left !important;
        font-size: 14px;
      }
    }
  }

  .hok-news-preview {
    .hok-news-preview-card {
      .card-body {
        padding: 0 0 1.25rem 0;

        .hok-news-preview-card-image {
          width: 100%;
          background-size: 100% auto;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 5px 5px 0 0;
        }

        .hok-news-preview-card-content {
          padding: 15px 30px;

          @include media-breakpoint-down(xs) {
            padding: 15px 16px;
          }

          h2 {
            font-size: 34px;
            color: $primary;
            font-weight: 500;
            margin-bottom: 5px;

            @include media-breakpoint-down(xs) {
              font-size: 24px;
            }
          }

          .hok-news-preview-card-published {
            font-size: 14px;
            color: $text;
            margin-bottom: 0;
          }

          p,
          ul,
          ol {
            font-size: 18px;
            color: $text-dark;

            @include media-breakpoint-down(xs) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .page-container {
    padding-right: 15px;
    padding-left: 15px;
    @media (max-width: 380px) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .ql-container {
    height: 400px;
  }

  .policies {
    p,
    li {
      font-size: 18px;
      color: $text-dark;
    }

    .hok-text-small {
      font-size: 14px;
      color: $text-dark;
    }

    ul {
      margin-bottom: 45px;
    }

    h5 {
      font-size: 16px;
      color: $primary;
      font-weight: bold;
    }

    .hok-health-info {
      h5 {
        font-size: 18px;
        color: $text-dark;
      }

      ol {
        counter-reset: list;
        padding-left: 24px;
      }

      ol > li {
        list-style: none;
      }
      ol > li::before {
        content: counter(list) "] ";
        counter-increment: list;
      }
    }
  }

  .hok-custom-select {
    font-weight: 500;
    color: $black;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 40px;
  }

  .hok-tac-checkbox {
    @extend .hok-tac-checkbox;
    padding-left: 1.4rem;
  }

  .tac-text-container {
    font-size: 16px;
    font-weight: 500;
    color: $text-dark;
    height: 100%;
    display: flex;
    align-items: center;

    .terms-and-conditions-text {
      cursor: pointer;
      text-decoration: underline;
      color: $primary;
      margin-left: 0.25rem;
      font-weight: 500;
    }
    a.terms-and-conditions-text {
      color: $primary;
      font-weight: 500;
    }
  }

  .step2 {
    .subtitle {
      font-size: 16px !important;
    }
  }

  .step3 {
    padding-bottom: 30px;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .step4 {
    h2 {
      font-size: 18px;
      font-weight: bold;
    }
    p {
      font-size: 18px;
    }
  }

  .hok-comment-textarea {
    border-radius: 10px;
    border: 1px solid $light-grey-color;
    height: 140px;

    @include media-breakpoint-down(xs) {
      min-height: 200px;
      resize: vertical;
    }
  }

  .hok-ticket-comment-box {
    border-radius: 10px;
    border: 1px solid $light-grey-color;
    min-height: 175px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(xs) {
      min-height: 200px;
      resize: vertical;
    }

    .hok-ticket-textarea-container {
      padding: 0.5rem 0 0.5rem 1rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: transparent;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .hok-ticket-textarea {
      border: none;
      min-height: 120px;
      flex: 1;
      padding: 0 1rem 0 0;

      @include media-breakpoint-down(xs) {
        height: 145px;
      }

      box-shadow: none;
      outline: none;
    }

    hr {
      margin: 0;
      background: $placeholder;
    }

    .hok-ticket-attachment-container {
      padding: 0.25rem 0.5rem 0.25rem 1rem;
      font-size: 16px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      flex: 1;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .hok-enquiry-textarea {
    border: 1px solid $light-grey-color;
    border-radius: 0.25rem;
    height: 160px;
    resize: vertical;

    @include media-breakpoint-down(xs) {
      min-height: 200px;
    }
  }

  .hok-note-textarea {
    height: 100px;
    min-height: 100px;
    resize: vertical;
  }

  .hok-faq-subtitle {
    font-weight: bold;
    font-size: 18px;
  }

  .hok-faq-group-title {
    font-size: 18px;
    text-transform: none;
  }

  .hok-faq-container {
    @extend .insurance-policy-container;
    box-shadow: none;
    border-radius: 10px;
    min-height: 48px;
    margin-bottom: 0;

    .card-header {
      border-radius: 10px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
      z-index: 2;
      background-color: $white;
      margin-bottom: -6px;
    }

    .hok-faq-collapse {
      border-radius: 0 0 5px 5px;
      z-index: 1;
    }

    .collapsing {
      border-top: none;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    }

    .show {
      border-top: none;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    }

    .hok-faq-search-input {
      border: none;
      background: transparent;
      height: auto;
      outline: none;
      box-shadow: none;
      font-size: 16px;
    }

    .card-body {
      .hok-faq-question {
        border-top: 1px solid #ebebeb;
        min-height: 50px;
        display: block;
        display: flex;
        align-items: center;
        padding: 0 10px 0 20px;
        text-decoration: none;
        font-weight: normal;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 16px;
        color: $text-dark;

        &:first-child {
          min-height: 56px;
          padding-top: 11px;
          border-top: 1px solid transparent;
        }
      }
    }
  }

  .hok-logo-red-short {
    width: 65px;
    height: auto;
    margin-bottom: 1.75rem;
  }

  .hok-comment-avatar {
    @extend .img-avatar;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .page-item {
    &.active {
      background-color: transparent;

      & > .page-link {
        color: $primary;
      }
    }

    &.disabled {
      background-color: transparent;
      opacity: 0.2;

      & > .page-link.arrow {
        color: #1d1d1d;
      }
    }

    .page-link {
      background-color: transparent;
      border: none;
      color: $black;
      font-weight: bold;
      font-size: 16px;
      padding: 0.5rem 6px;

      &.arrow,
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: $primary;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .hok-comments-spinner-container {
    position: relative;
  }
  .hok-comments-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
  }

  .hok-comments-container {
    display: flex;
    flex-direction: column-reverse;

    &.loading {
      opacity: 0.2;
    }

    .hok-comment-box {
      h5 {
        text-transform: none;
        font-size: 18px;
        font-weight: bold;
        display: inline-block;

        @include media-breakpoint-down(xs) {
          display: block;
        }
      }

      pre {
        font-size: 16px;
        color: $text-dark;
        font-family: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .hok-comment-date {
        font-size: 14px;
        color: $text-dark;
        opacity: 0.7;
        float: right;

        @include media-breakpoint-down(xs) {
          float: left;
        }
      }

      &:not(:first-child) {
        border-bottom: 1px solid $light-grey-color;
      }
    }
  }

  .hok-comment-file {
    display: inline-flex;
    align-items: center;
    padding: 0.15rem 0;

    fill: $black;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    .file-label {
      font-size: 16px;
      word-break: break-all;

      &.red {
        color: $primary;
        text-decoration: underline;
      }

      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }
  }

  .hok-text-dark {
    @extend .hok-text-dark;
  }

  .hok-text-dark-500 {
    @extend .hok-text-dark-500;
  }

  .hok-docs-dropzone {
    min-height: 200px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 2px dashed $light-grey-color;
    border-radius: 5px;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;

    &:focus {
      border-color: $primary;
    }

    img {
      text-align: center;
      height: 84px;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      color: $text-dark;
    }

    span {
      display: block;
    }

    .emphasize {
      color: $primary;
      text-decoration: underline;
    }
  }

  .hok-audit-logs-table {
    box-shadow: 0 3px 6px #00000029;

    .-header {
      height: 38px;

      .rt-resizable-header {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .-pagination {
      box-shadow: none;
    }
  }

  .hok-admin-home-table {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16) !important;

    &.transactions-table {
      .transactions-table-title {
        border-bottom: 1px solid $light-grey-color;
        height: 48px;

        .rt-th {
          justify-content: space-between !important;

          @include media-breakpoint-down(sm) {
            justify-content: flex-start !important;
          }
        }
      }
      .rt-thead {
        .rt-tr {
          height: 48px;
        }
      }

      button {
        width: 160px;
        height: 34px;
        padding: 0 12px;

        @media (max-width: 300px) {
          display: block;
          width: 100%;
        }
      }
    }

    &:not(.transactions-table) {
      .rt-thead {
        .rt-th {
          display: block;
        }
      }

      .pagination-bottom {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding: 8px 10px;

        button {
          width: 160px;
          height: 34px;
          padding: 0 12px;

          @media (max-width: 300px) {
            display: block;
            width: 100%;
          }
        }
      }
    }

    .rt-tbody {
      border: none;
      border-top: 1px solid $light-grey-color;

      .rt-tr {
        cursor: pointer;
      }

      .rt-td {
        border: none;
        height: 41.86px;
        padding: 7px 20px !important;

        @include media-breakpoint-only(xs) {
          padding: 7px 10px !important;
        }
        @include media-breakpoint-only(md) {
          padding: 7px 10px !important;
        }
      }
    }
  }

  .hok-custom-table-header {
    min-height: 50px;
    padding: 0 5px;

    @include media-breakpoint-only(md) {
      display: block;
      float: none;
      text-align: left;
      height: auto;
    }

    .rt-tr {
      @include media-breakpoint-only(lg) {
        display: block;
      }
      @include media-breakpoint-only(md) {
        display: block;
      }
      @media (max-width: 450px) {
        display: block;
      }
    }

    h2 {
      margin-bottom: 0;
      color: $primary;
      font-size: 20px;
      font-weight: bold;
    }

    .hok-title-wrapper {
      display: inline-flex;
      align-items: center;
      float: left;
      height: 100%;

      @include media-breakpoint-only(lg) {
        display: block;
        float: none;
        text-align: left;
        height: auto;
      }
      @include media-breakpoint-only(md) {
        display: block;
        float: none;
        text-align: left;
        height: auto;
      }
      @media (max-width: 450px) {
        display: block;
        float: none;
        text-align: left;
        height: auto;
      }
    }

    .hok-input-wrapper {
      display: inline-flex;
      align-items: center;
      height: 100%;
      width: 150px;
      float: right;

      @include media-breakpoint-only(lg) {
        display: block;
        float: none;
        margin: 16px 0 5px;
      }
      @include media-breakpoint-only(md) {
        display: block;
        float: none;
        margin: 16px 0 5px;
      }
      @media (max-width: 450px) {
        display: block;
        float: none;
        margin: 16px 0 5px;
      }

      input,
      select {
        border-radius: 5px !important;
        border: 0.5px solid $placeholder !important;
      }
    }
  }

  .hok-invoice-status {
    &.is-invalid {
      background-image: url("../img/icons/hok-error.svg") !important;
      background-repeat: no-repeat !important;
      background-position-x: 95% !important;
      background-position-y: center !important;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    }
  }

  .hok-mx-negative {
    margin-left: -15px;
    margin-right: -15px;
  }

  .hok-office-card-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: inline-flex;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    min-width: 380px;

    @media (max-width: 400px) {
      min-width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .hok-office-card {
    border: 1px solid $light-grey-color;
    box-shadow: none;
    height: 220px;
    width: 100%;

    @media (max-width: 400px) {
      min-height: 200px;
      height: auto;
    }

    .card-body {
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .hok-office-card-title {
        flex: 1 1;
        margin-right: 8px;
      }

      h5 {
        font-size: 16px;
        color: $text-dark;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      p {
        font-size: 16px;
        margin-bottom: 0;
        color: $text-dark;
      }

      .hok-google-maps-link {
        height: fit-content;
      }

      .hok-phone-number {
        color: $primary;
        font-weight: 500;
      }
    }
  }

  .hok-ticketing-card {
    & > .card-body {
      padding: 5px;
      border-radius: 5px;
      box-shadow: none;
      text-align: center;
      color: $text-dark;

      .padding {
        padding: 8px 0;
      }

      p {
        font-size: 16px;
        margin-bottom: 0;
        color: $text-dark;
        line-height: 18px;
      }

      .amount {
        font-size: 28px;
        font-weight: bold;
      }

      .red-border {
        border-right: 1px solid $primary;

        @include media-breakpoint-down(sm) {
          border-right: none;
        }
      }
    }
  }

  .hok-ticket-card-header {
    display: flex;

    & > div {
      flex-flow: wrap;
      flex: 1;

      .main-title {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media-breakpoint-down(sm) {
          min-width: auto;
          width: auto;
        }
      }

      .hok-ticket-number {
        width: 175px;
        @include media-breakpoint-down(xs) {
          width: auto;
        }
      }

      .subtitle-text {
        display: block;
        line-height: 20px;
        @include media-breakpoint-down(sm) {
          flex-basis: 100%;
          margin-top: 5px;
          margin-left: 0;
        }
      }

      .hok-ticket-title {
        text-transform: none;
        font-weight: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        display: inline-block;

        @include media-breakpoint-up(sm) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
        }
      }
    }

    .hok-ticket-mobile-warning {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
        margin: auto 0;
      }
    }
  }

  .ticket-title {
    color: $black;
  }

  .button-position {
    position: absolute;
    bottom: 0;
  }

  .hok-price-box {
    width: 90px;
    text-align: right;

    @include media-breakpoint-down(xs) {
      width: auto;
    }
  }
}

.hok-cancel-link {
  color: $primary;
  cursor: pointer;

  &:hover {
    span {
      color: #b00e13;
      text-decoration: underline;
    }
  }
}

.hok-user-transactions-box {
  width: 100%;
  min-height: 50px;
  background: rgba(203, 204, 206, 0.15) !important;
  border: 1px solid $light-grey-color;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  color: #5c6873;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    width: 100%;
  }

  &.hok-error-description {
    overflow: hidden;
    word-break: break-word;
    white-space: break-spaces;
    text-align: left;
  }
}

.hok-user-transaction-mngmt {
  border: 1px solid $light-grey-color;
  border-radius: 10px;
  padding: 20px;

  @media screen and (min-width: 500px) and (max-width: 767px) {
    padding: 40px;
  }

  @media screen and (min-width: 1450px) {
    padding: 40px;
  }

  .hok-user-transaction-status-box {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    .hok-user-transaction-status-header {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      h4 {
        text-transform: none;
        font-size: 16px;
        color: $text;
        font-weight: bold;
        margin-bottom: 0;
        margin-right: 10px;
        min-height: 32px;
      }

      .hok-user-transaction-refresh {
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          .svg-red {
            background-color: $primary;
            border-radius: 50%;
            fill: $white;
            & > * {
              fill: $white !important;
            }
          }
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .svg-gray.active {
          fill: $primary;
          & > * {
            fill: $primary !important;
          }
          animation: rotate 1s linear infinite;
        }
      }
    }

    .hok-user-transaction-status-content {
      display: flex;

      .hok-user-transaction-status-icon {
        margin-right: 20px;
      }

      .hok-user-transaction-status-description {
        h5 {
          margin: 0.5rem 0;
          font-size: 18px;
          font-weight: bold;
          color: $text-dark;
          text-transform: uppercase;
        }

        p {
          margin-bottom: 0;
          font-size: 12px;
          color: $text-dark;
        }
      }
    }
  }
}

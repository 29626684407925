.fixed-width-card-group {
  width: 730px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.card {
  h2 {
    font-size: 22px;
    margin-bottom: 0;
    text-decoration: none;
    color: $primary;

    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
  }

  .card-header {
    background: none;
    border-bottom: 3px solid $primary;
    height: 50px;
    padding: 10px 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .card-header-image {
      max-height: 30px;
    }
  }

  .card-body {
    color: $text;

    .content-card {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      border: none;

      .card-header {
        height: auto;
      }
    }

    .hok-partners-card {
      border-radius: 10px;
      max-width: 250px;
      border-right: 10px solid transparent;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;

      .card-body {
        padding: 0.75rem;
      }

      h4 {
        font-size: 18px;
        color: $primary;
        text-transform: none;
        font-weight: bold;
      }
    }

    .hok-partners-border-red {
      border-right: 10px solid $primary;
    }

    .link-back {
      font-size: 14px;
      color: $primary;
      font-weight: bold;
      margin-left: 20px;
      margin-bottom: 1rem;
      display: block;

      @include media-breakpoint-down(xs) {
        margin-left: 0;
      }
    }

    .link-logout {
      font-weight: bold;
      margin-right: 20px;
      color: $primary;

      @include media-breakpoint-down(xs) {
        margin-right: 0;
      }
    }

    .main-content {
      margin: 100px 0;

      &.narrow {
        margin: 60px 0;
      }
    }

    .content-box {
      display: inline-block;
      vertical-align: middle;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      height: 150px;
      width: 150px;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      margin-left: 30px;
      text-decoration: none;

      &:first-child {
        margin-left: 0;
        margin-right: 30px;
      }

      @include media-breakpoint-down(xs) {
        display: block;
        margin: 30px auto 0;

        &:first-child {
          margin: 0 auto;
        }
      }

      .content-icon {
        height: 60%;

        .content-box-icon {
          height: 50px;
          width: 50px;

          fill: $primary;
          & > * {
            fill: $primary !important;
          }
        }
      }

      .content-title {
        height: 40%;
        h2 {
          color: $text-dark;
          font-size: 20px;
        }
      }
    }
  }

  .card-footer {
    background: none;
    border: none;
    padding-bottom: 0.5rem;

    .card-footer-image {
      max-height: 40px;
    }

    a {
      font-size: 16px;
      color: $text-dark;
      text-decoration: underline;
    }

    .hok-card-logout-link {
      color: $primary;
      text-decoration: none;

      &:hover,
      &.hover {
        text-decoration: underline;
      }
    }
  }
}

.app-header {
  background-color: $black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  border: none;
  color: $white;

  .user-navbar-brand {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -77.5px;
    width: 155px;
    height: 55px !important;

    @include media-breakpoint-up(lg) {
      width: 200px;
    }

    .navbar-brand {
      position: static;
      margin-left: 0;
      width: 155px;
      @include media-breakpoint-up(lg) {
        width: 200px;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-left: -100px;
    }
  }

  .navbar-brand {
    height: auto;
  }

  .hok-admin-db-title {
    font-size: 22px;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 50%;
      top: 20%;
      margin-left: -139px;
    }
  }

  .navbar-toggler-icon {
    height: 23px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23ffffff' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .navbar-toggler:hover .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23ffffff' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .hok-brand-mini {
    position: static;
    margin: 0;
    width: auto;
    height: 55px !important;
  }

  .navbar-nav {
    color: $white;
    height: 100%;

    .nav-item {
      height: 100%;
    }

    .hok-profile-dropdown {
      width: 250px;

      .hok-dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0 20px;
        height: 100%;

        .hok-dropdown-toggle-name {
          flex: 1;
          text-align: right;
          word-break: break-word;
        }
      }

      &.show {
        background-color: lighten($black, 20%);
      }

      .hok-profile-dropdown-menu {
        margin: 0;
        width: 250px;
        transform: translate3d(0, 55px, 0) !important;
        border: none;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background: $white;
        padding: 10px 0;

        .dropdown-item {
          font-size: 16px;
          border: none;
          color: $text;
        }

        .dropdown-divider {
          border-top: 1px solid #707070;
          margin: 8px 20px;
        }
      }

      @include media-breakpoint-down(sm) {
        width: auto;

        .hok-profile-dropdown-menu {
          width: 200px;
          transform: translate3d(-124px, 55px, 0) !important;
        }
      }
    }
  }

  .hok-brand-center {
    @include media-breakpoint-up(lg) {
      width: 200px;
    }
  }

  .nav-link {
    color: $white !important;
    margin: 0 20px;
  }

  .nav-item .nav-link {
    .hok-img-avatar {
      margin: 0;
      height: 35px;
      width: 35px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 35px;
      background-color: $white;
    }
  }

  .hok-notifications {
    display: flex;
    align-items: center;
    justify-content: center;

    &.show {
      background-color: lighten($black, 20%);
    }

    .hok-notifications-toggle {
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
    }

    .hok-notifications-link {
      height: 35px;
      width: 100%;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border-left: 1px solid $white;
    }

    .badge {
      background: #8d1417;
      color: $white;
      border-radius: 25px;
      font-variant-numeric: tabular-nums;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hok-notifications-dropdown-menu {
      transform: translate3d(-294px, 55px, 0) !important;
      width: 350px;
      border: none;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
      margin: 0;
      max-height: 500px;
      height: auto;
      overflow-y: auto;

      @include media-breakpoint-only(xs) {
        width: 250px;
        transform: translate3d(-194px, 55px, 0) !important;
      }

      .hok-notification-link {
        text-decoration: none;

        &:active {
          color: unset;
        }
      }

      .dropdown-item {
        padding: 15px 25px 15px 20px;
        border: none;

        &:first-child {
          border-radius: 0;
        }

        .hok-notifications-title {
          font-size: 22px;
          color: $primary;
          flex: 1;
        }

        .hok-notifications-close {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          padding: 0;
          border: 0;
          box-shadow: none;

          &::before {
            content: "";
            display: block;
            height: 0;
            width: 0;
            margin-top: -5px;
          }

          &.unseen {
            background-color: $primary;
          }

          &.seen {
            background-color: $borders;
          }
        }

        .hok-notifications-content {
          font-size: 14px;
          white-space: normal;
        }
      }

      .dropdown-divider {
        border-top: 1px solid #707070;
        margin: 5px 20px;
      }
    }
  }

  .dropdown-item {
    svg {
      margin-right: 10px;
    }

    &.active,
    &:active {
      background-color: #f0f3f5;
      color: unset;
    }
  }
}

.hok-sidebar,
.hok-sidebar-footer {
  background-color: $text-dark !important;
}

.hok-sidebar {
  .nav {
    padding-top: 10px;

    .nav-item {
      width: 100%;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    border-left: 3px solid transparent;
    color: #f5f5f5;
    opacity: 0.8;

    &.active,
    &:active,
    &:hover {
      background: rgba(256, 256, 256, 0.2);
      border-left: 3px solid $primary;
      opacity: 1;
    }

    &.active .nav-icon {
      color: $white;
      opacity: 1;
    }

    .nav-icon {
      width: auto;
      max-width: 1.5rem;
      font-size: 16px;
      margin-right: 15px;
      color: #f5f5f5;
      opacity: 0.8;
    }
  }

  .nav-title-divider {
    color: $primary;
    padding: 10px 0;

    hr {
      color: $primary;
      background: $primary;
      margin: 0;
    }
  }
}

.hok-sidebar-footer {
  text-align: center;
  font-size: 10px;
  color: #cbccce;
  overflow-wrap: break-word;
}

.hok-button {
  border-radius: 50em;
  font-size: 16px;
}

.hok-upload-button {
  @extend .hok-button;
  @extend .hok-outline-btn;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hok-support {
  margin-bottom: 0;
}

.hok-text {
  font-size: 16px;
  font-weight: 500;
  color: $text;
  margin-bottom: 1rem;
}

.hok-version {
  color: #9a9b9c;
  font-size: 10px;
  margin-top: 10px;
}

.lottie {
  background-color: #fff;
  width: 100%;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
  position: fixed;
  bottom: 0;
}

.auth-container {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  .form-group {
    margin-bottom: 0.5rem;
  }

  .invalid-feedback {
    margin-bottom: -0.5rem;
    color: $invalid-color;
  }

  .form-control {
    padding-left: 0;
    padding-right: 0;
  }

  .hok-verify-radio {
    @media (max-width: 400px) {
      display: block;
      float: left;
    }
  }

  .hok-reg-checkbox {
    padding-left: 2.5rem;

    .custom-control-label {
      font-size: 16px;
      font-weight: 500;

      &::before {
        border: 2px solid $primary;
        height: 20px;
        width: 20px;
        left: -2.5rem;
        background-color: $white;
      }

      &::after {
        left: -2.5rem;
        height: 20px;
        width: 20px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("../img/icons/tick.svg");
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      background-color: unset;
    }
  }

  input,
  select {
    border: none;
    border-bottom: 1px solid $input-borders;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    color: $input-borders;
    font-size: 16px;

    &::placeholder {
      color: $placeholder;
      opacity: 0.5;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background-color: rgba(228, 231, 234, 0.5);
    }
  }

  .hok-confirmation-code-input {
    text-transform: uppercase;
    &::placeholder {
      text-transform: none;
    }
  }

  .input-group-text {
    border: none;
    border-bottom: 1px solid $input-borders;
    border-radius: 0;
    background: none;
    padding-right: 0;
  }

  .input-group-prepend,
  .input-group-append {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $input-borders;
    color: $input-borders;
    margin: 0;
  }

  .form-control.is-invalid,
  .input-group-prepend.is-invalid,
  .input-group-append.is-invalid {
    background-image: none;
    color: $invalid-color;
    border-color: $invalid-color;

    &:focus {
      box-shadow: none;
    }
  }

  .form-control:focus {
    border-color: $focused-color;
  }

  .input-group-prepend.focused,
  .input-group-append.focused {
    color: $focused-color;
    border-color: $focused-color;
  }

  .PhoneInput {
    //width: 97%;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    &.form-control {
      height: 38px;

      & > .PhoneInputCountry {
        height: 26px;
        display: inline-block;
      }
    }

    .PhoneInputInput {
      display: inline-block;
      height: 100%;
      padding-bottom: 6px;
      padding-top: 6px;
    }
  }
  .PhoneInputCountrySelectArrow {
    display: inline-block;
  }
  .PhoneInputCountryIcon {
    display: inline-block;

    svg {
      height: 100%;
    }
  }

  .auth-card-group {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .auth-card {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
    border: none;
    // stylelint-disable
    -ms-flex: none;
    // stylelint-enable
    border-radius: 5px;
  }

  .container-image {
    background-image: url("../img/container-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    border-radius: 5px 0 0 5px;
    align-self: stretch;
    background-position: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .container-form {
    width: 460px;
    float: left;
    color: $text;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .container-form-aux {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      height: 100%;

      @include media-breakpoint-down(sm) {
        min-height: 461px;
      }
    }

    .no-border * {
      border-bottom: none !important;
    }

    .auth-nav-links {
      height: 50px;

      a,
      h2 {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 22px;
        border-bottom: 3px solid #cbccce;
        text-decoration: none;
        color: $primary;

        // stylelint-disable
        &:active,
        &.active {
          border-bottom: 3px solid $primary;
        }
        // stylelint-enable
      }

      h2 {
        border-color: $primary;
      }
    }

    p {
      font-size: 16px;

      @include media-breakpoint-down(md) {
        font-size: 14px !important;
      }
    }

    .resend-code {
      font-size: 14px !important;
      color: $text-dark;
    }

    .resend-link {
      text-decoration: underline;
      cursor: pointer;
    }

    .logo-short {
      @include media-breakpoint-only(md) {
        max-width: 90%;
      }

      @include media-breakpoint-only(xs) {
        @media (min-width: 400px) {
          max-width: 70%;
        }
      }
    }

    .hok-modal-toggler {
      cursor: pointer !important;
      font-size: 16px;
    }

    a {
      font-size: 16px;
      color: $text-dark;
      text-decoration: underline;
    }

    .hok-support-icon-label {
      @extend .hok-support-icon-label;
    }
  }

  @import "content";
  @import "dashboard-buttons";
}

.quick-report {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  .quick-report-card-group {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    & > .card {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
      border: none;
      // stylelint-disable
      -ms-flex: none;
      // stylelint-enable
      border-radius: 5px;

      & > .card-header {
        background-color: $primary;
        border-bottom: none;
        position: relative;
        border-radius: 5px 5px 0 0;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          color: $white;
          margin-bottom: 0;
          font-size: 22px;
        }
      }

      .card-body {
        border-radius: 0 0 5px 5px;
        background-color: $white;
        box-shadow: none;
      }
    }
  }
}

.hok-back-icon {
  position: absolute;
  right: 10px;
  top: 14.5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.hok-btn {
  width: 160px;
  border-radius: 50em;

  &:disabled {
    background: #9e9e9e;
    border-color: #9e9e9e;
  }
}

.hok-dashboard-btn {
  @extend .hok-btn;
  height: 50px;
}

.hok-dashboard-btn-wide {
  @extend .hok-dashboard-btn;
  max-width: 100%;
  width: 270px !important;
}

.hok-outline-btn {
  border-width: 2px;
  font-weight: 500;
  width: 160px;
  height: 50px;
}
.hok-outline-btn-wide {
  @extend .hok-outline-btn;
  width: 287px;
  @media (max-width: 400px) {
    width: 200px;
  }
}

.hok-outline-dashboard-btn-wide {
  height: 50px;
  max-width: 100%;
  width: 270px !important;
  border-width: 2px;
  font-weight: 500;
}

.hok-icon-btn {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  color: inherit;

  &:active,
  &.active {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.hok-rounded-icon-btn {
  @extend .hok-icon-btn;
  border-radius: 50%;
}

.hok-square-icon-btn {
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 0;
  outline: none;
  box-shadow: none;

  &.btn-outline-primary,
  &.btn-outline-primary:active,
  &.btn-outline-primary:focus {
    background-color: $white !important;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  &:active {
    border: 2px solid $primary !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: $primary !important;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    background: #9e9e9e;
    border-color: #9e9e9e;
  }
}

.hok-doc-upload-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid $light-grey-color;
  border-radius: 10px;
  min-height: 62px;
  box-shadow: 0 2px 4px #00000029;
  font-size: 18px;
  color: $primary;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    outline: none !important;
  }

  .hok-doc-upload-content {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    padding-right: 10px;
    overflow: hidden;
    margin-bottom: 0;

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      color: $text-dark;
    }

    .hok-doc-name {
      font-size: 18px;
      color: $primary;
    }
  }
}

.hok-sm-outline-btn {
  @extend .hok-button;
  @extend .btn-outline-primary;
  font-weight: normal;
  color: $text-dark !important;
  cursor: pointer;
  min-width: 145px;
  height: 35px;
  font-size: 14px;

  & > input {
    opacity: 0;
    overflow: hidden;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }

  &:hover,
  &:focus,
  &:active {
    color: $white !important;
    background-color: $primary;
  }
}

.hok-add-doc-btn {
  background: transparent;
  color: $primary;
  border: none;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  box-shadow: none;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: $primary !important;
  }

  svg {
    fill: $primary;
    height: 18px;
    width: 18px;
    margin-right: 10px;
  }
}

.hok-attachement-add-button {
  @extend .hok-sm-outline-btn;
  @extend .hok-square-icon-btn;
  min-width: unset;
  height: 38px;
  width: 38px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus,
  &:active {
    background-color: $white !important;
  }
}

.hok-ghost-button {
  background: transparent;
  border: none;
  border-radius: 0;
  font-size: 16px;
  color: $text-dark;
  box-shadow: none !important;
  outline: none !important;
  height: 50px;

  &:hover,
  &:active {
    background-color: #f0f3f5 !important;
    border: none !important;
  }
}

.hok-document-link {
  display: flex;
  align-items: center;
  text-decoration: none;

  .hok-document-link-icon {
    width: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .hok-document-link-content {
    font-size: 16px;
    color: $primary;
    font-weight: bold;
  }
}

.hok-policy-action-btn {
  display: inline-block;
  max-width: 230px;
  margin-top: 1rem;

  @include media-breakpoint-only(xs) {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}

.hok-outline-dark {
  color: $text;
  border-color: $input-borders;

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5);
  }

  &:not(:disabled):not(.disabled):active {
    color: $white;
    background-color: $input-borders;
    border-color: $input-borders;
  }

  &:hover {
    color: $white;
    background-color: $input-borders;
    border-color: $input-borders;
  }
}

// Variable overrides
$theme-colors: (
  // inactive: #6c757d,
    // purple: #6f42c1,
) !default;

$primary: #ed1d24 !default;
// $secondary: #20a8d8;

$hok-red: #bc2f2f !default;
$borders: #e4e7ea !default;

$input-borders: #5e5e5e !default;
$text: #5e5e5e !default;
$text-dark: #231f20 !default;
$invalid-color: #c5191e !default;
$focused-color: #ed1d24 !default;
$placeholder: #707070 !default;
$light-grey-color: #cbccce !default;

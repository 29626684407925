@font-face {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  /* stylelint-disable */
  /* prettier-ignore */
  src: url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.svg") format("svg");
  /* stylelint-enable */
}

body {
  background: #fff;
  margin: 0;
  padding: 0 !important;
  /* stylelint-disable */
  /* prettier-ignore */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  /* stylelint-enable */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main {
  flex-shrink: 0;
}

/* stylelint-disable declaration-colon-space-after, value-list-comma-newline-after  */
.font-weight-500 {
  font-weight: 500;
}
/* stylelint-enable */

.hok-text-dark {
  font-size: 16px;
  color: $text-dark;
}

.hok-font-small {
  font-size: 12px;
}

sup {
  font-size: 10px;
}

.hok-text-dark-500 {
  @extend .hok-text-dark;
  font-weight: 500;
}

.w-xs-100 {
  @include media-breakpoint-only(xs) {
    width: 100% !important;
  }
}

h4 {
  text-transform: uppercase;
  color: $gray-600;
}

.hok-cookie-policy {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #404040;
  min-height: 120px;
  display: block;
  align-items: center;
  padding: 20px;
  justify-content: center;

  @include media-breakpoint-up(md) {
    display: inline-flex;
  }

  @include media-breakpoint-up(md) {
    padding: 24px 36px;
  }

  @include media-breakpoint-up(lg) {
    padding: 24px 50px;
  }

  .hok-cookie-policy-text {
    @include media-breakpoint-up(md) {
      margin-right: 36px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 50px;
    }

    @media (min-width: 1450px) {
      margin-right: 100px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      color: $white;
    }
  }

  .hok-cookie-policy-buttons {
    @include media-breakpoint-only(xs) {
      display: flex;
      flex-direction: column-reverse;

      button:not(:last-child) {
        margin-top: 20px;
      }
    }

    @include media-breakpoint-only(sm) {
      text-align: right;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }

    .hok-outline-btn {
      background-color: $white;

      &:hover,
      &:active {
        background-color: $primary;
      }
    }
  }
}

.hok-download-app-img {
  a:first-child {
    margin-right: 20px;
  }

  a:last-child {
    margin-left: 20px;
  }

  @media (max-width: 319px) {
    a {
      margin: 0 !important;
      display: block;
    }
  }
}

.img-app-download {
  display: inline-block;
  background-size: auto;
  background-repeat: no-repeat;
  height: 30px;
}

.app-store {
  background-image: url("../img/app-store.svg");
  width: 89.75px;
}

.google-play {
  background-image: url("../img/google-play.svg");
  width: 100.54px;
}

.container-fluid {
  margin-top: 30px;
  margin-bottom: 3rem;
  padding-left: 3rem !important;
  padding-right: 3rem !important;

  @include media-breakpoint-down(xs) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@include media-breakpoint-down(xs) {
  .RFS-StepperContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// REACT-DATES INPUT STYLED TO LOOK LIKE BOOTSTRAP INPUT

.CalendarMonthGrid {
  .CalendarMonth_caption {
    padding-top: 18px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $primary;
    border: 1px double $primary;
    color: #fff;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: rgba(237, 29, 36, 0.3);
    border: 1px solid #bb0f15;
    color: $primary;
  }

  .CalendarDay__selected_span {
    background: rgba(237, 29, 36, 0.5);
    border: 1px solid #bb0f15;
    color: $white;

    &:active,
    &:hover {
      background: rgba(237, 29, 36, 0.85);
    }
  }

  select {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff;
    border-radius: 3px;
    height: 33px;
    font-size: 14px;
    width: 85px;
  }
}

.SingleDatePicker {
  width: 100%;

  .SingleDatePickerInput__disabled {
    .DateInput_input__disabled {
      background-color: unset !important;
      font-style: normal;
    }
  }

  .SingleDatePickerInput {
    width: 100%;
    height: 38px;
    border: none;
    border-bottom: none;
    border-radius: 0;
    display: flex;

    .SingleDatePickerInput_calendarIcon {
      //padding-top: 5px;
      //padding-bottom: 5px;
      margin-left: 0;
      display: flex;
      align-items: center;
    }

    .SingleDatePickerInput_clearDate__small {
      line-height: 1.2em;
    }

    .DateInput {
      width: 100%;

      &:focus {
        outline: none;
      }

      .DateInput_input {
        border-bottom: 1px solid $input-borders;
        line-height: 20px;
        height: 38px;
        padding-left: 0;
        padding-right: 0;
        font-size: 16px;
        letter-spacing: normal;
        font-weight: normal;
        color: $text;

        &:focus {
          outline: none;
        }
      }

      .DateInput_input__focused {
        border-bottom: 1px solid $focused-color;
        outline: none;
      }

      .DateInput_input__small {
        font-size: 16px;
      }
    }
  }
}

.invalid-date {
  .SingleDatePicker {
    .SingleDatePickerInput {
      border: 1px solid #f86c6b !important;
    }
  }
}

// FORM INPUT FIELDS FEEDBACK/ERROR
.form-feedback {
  font-size: 16px;
  text-align: center;
  color: #f86c6b;
  margin-top: 10px;
}

.invalid-feedback::first-letter {
  text-transform: capitalize;
}

// REACT-TABLE STYLING
// CURSOR TO POINTER ON TABLE ROW HOVER CAUSE ROWS ARE LINKS
.business-plans {
  .rt-tbody {
    .rt-tr-group {
      cursor: pointer;
    }
  }
}

.ReactTable {
  background: $white;
  border: none;
  border-radius: 5px;

  .rt-thead {
    .rt-th {
      border: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .-filters {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    background: $white;
  }

  .rt-tbody {
    overflow-x: hidden !important;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  }

  input,
  select {
    height: 31px !important;
    border: 1px solid #707070 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0.25rem 0.75rem;
  }

  .DateRangePicker {
    .DateRangePickerInput {
      border: none;

      .DateRangePickerInput_clearDates {
        margin: 0;
        display: flex;
        align-items: center;
        height: 29px;
      }
    }
  }

  .pagination-bottom {
    .-btn {
      background-color: $primary;
      color: $white;
      display: inline-block;
      width: 160px;
      border-radius: 50em;

      @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
      }

      &[disabled] {
        background-color: #9e9e9e;
        color: $white;
        opacity: 1;
      }
    }

    .-center {
      flex: unset;
    }
  }
}

.react-table-headers {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 14px;
  font-weight: 500;

  &.rth-edit > div {
    width: 100%;
    justify-content: space-around;
  }

  & > div > .custom-checkbox.custom-control.custom-control-inline {
    padding-left: 0;
    margin-right: 0;

    & > .custom-control-label {
      margin-left: 30px;
    }
  }
}

.react-table-cells {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-weight: 500;
  font-size: 16px;
  color: $text-dark;

  & > .custom-checkbox.custom-control.custom-control-inline {
    padding-left: 0;
    margin-right: 0;

    & > .custom-control-label {
      margin-left: 30px;
    }
  }
}

.hok-simple-table {
  .rt-thead {
    height: 50px;
    .rt-th,
    .rt-th > div {
      display: flex;
      align-items: center;
    }
  }

  .react-table-cells {
    font-weight: normal;
    display: flex;
    align-items: center;
  }
}

.login-button {
  // padding-left: 35px;
  // padding-right: 35px;
  border-radius: 50em;
  min-width: 110px;
}

// CUSTOM SPINNER COMPONENT
.spinner-positioning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.input-spinner {
  height: 48px;
  width: 40px;
  position: absolute;
  right: 1px;
  top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}

.spinner-container {
  padding: 4px 0;
}

.spinner-border {
  display: block;
  margin: auto;
}

.spinner-border-thin {
  border-width: 0.1rem;
}

// REMOVE FIREFOX DOTTED OUTLINE
a:active,
a:focus {
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
}

// REMOVE YELLOW INPUT BACKGROUND ON AUTOFILL IN CHROME
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  background-color: $white !important;
  box-shadow: 0 0 0 1000px $white inset !important;
  -webkit-text-fill-color: #5c6873;

  &:disabled {
    background-color: #f7f7f8 !important;
    box-shadow: 0 0 0 1000px #f7f7f8 inset !important;
    -webkit-text-fill-color: #5c6873 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hok-action-text {
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.hok-toast-container {
  top: 4em;
}

.Toastify__toast--error {
  background-color: $hok-red;
}

@media (max-width: 876px) {
  .hok-user-dashboard {
    margin-left: 0 !important;
  }
  .sidebar-show .main::before,
  .aside-menu-show .main::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1018;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    animation: opacity 0.25s;
  }
}

.sidebar-show .main {
  position: relative;
}

.insurance-policy-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  p {
    margin-bottom: 0 !important;
  }

  .main-title {
    min-width: 180px;

    @include media-breakpoint-down(xs) {
      min-width: auto;
    }
  }

  h4 {
    margin-bottom: 0 !important;
  }
  .status-text {
    width: 65px;
    text-align: right;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    font-size: 14px;
    margin-bottom: 0 !important;
    padding: 0 0 0 15px;
  }
  .status-icon {
    padding: 15px 0 0 5px;
  }
  .card-header {
    background-color: $white;
    border-bottom: unset;
    padding-right: 10px;
    border-radius: 5px;

    @include media-breakpoint-down(xs) {
      padding-left: 10px;
    }
  }

  .card-body {
    padding: 0 0 0 15px;
    height: 100%;
  }
  .card-text {
    height: 50px;
  }
  .keyboard-icon {
    margin: 16px 11px 0 10px;
    float: right;

    &:hover {
      cursor: pointer;
    }
  }
  .subtitle-text {
    font-size: 16px;
    color: #000;
    margin-left: 15px;
  }

  .mobile-container {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    .subtitle-text {
      padding-top: 0 !important;
      margin-left: 0;
    }
    .desktop-container {
      display: none;
    }
    .mobile-container {
      display: flex;
      align-items: center;
    }

    .collapse-toggler {
      margin-left: auto;

      &.align-top {
        align-self: flex-start;
      }
    }
  }
  .show {
    border-top: 1px solid #cbccce;
  }
  .collapsing {
    border-top: 1px solid #cbccce;
    width: 100%;
  }
  button {
    max-width: 287px;
  }
}

.collapsible-text-info-container {
  p {
    font-size: 16px;
    color: $text-dark;
    padding: 0 0 0 15px;
    margin: 0;
  }
  img {
    padding: 0 11px 0 10px;
  }
  .card-body {
    padding: 0;
  }
  .body-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .body-text-70 {
    @extend .body-text;
    height: 70px;
  }
  .body-text-border {
    @extend .body-text;
  }
  .expanded-container {
    padding: 8px 20px 17px 20px;
    p {
      padding: 0;
    }
    .text {
      font-size: 16px;
      color: $text-dark;
      padding: 0;
      font-weight: 0;
    }
    .placeholder {
      @extend .text;
      padding-right: 10px;
      font-weight: 500;
    }

    hr {
      width: 85%;
    }
  }
  .expanded-container-text {
    padding: 15px 20px 16px 20px;
    p {
      font-size: 14px;
    }
  }
  .card {
    border: 1px solid #cbccce !important;
    box-shadow: none !important;
  }
  .collapsible {
    width: 100%;
    border: 1px solid #cbccce;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    position: absolute;
    z-index: 10;
  }
  .collapsible-pushdown {
    @extend .collapsible;
    border: none;
    position: relative;
  }
}

.button-arrow-right {
  @extend .collapsible-text-info-container;
  height: 50px !important;
  box-shadow: none;
  .body-text {
    height: 50px;
  }
  p {
    font-size: 16px;
  }
  .card {
    border: 1px solid #cbccce !important;
    box-shadow: none !important;
  }

  &.invalid {
    .card {
      border: 1px solid #f86c6b !important;
    }
  }
}

.collapsible-container {
  p {
    margin-bottom: 0 !important;
    padding: 17px 0 9px 15px;
  }
  h4 {
    margin-bottom: 0 !important;
  }
  .card {
    //border: 1px solid #cbccce !important;
    box-shadow: none !important;
  }
  .main-title {
    font-size: 21px;
    letter-spacing: 0;
    color: $text-dark;
    text-transform: uppercase;
    opacity: 1;
    @include media-breakpoint-only(xs) {
      font-size: 18px;
    }
  }
  .main-title-red {
    @extend .main-title;
    color: $primary;
  }
  .status-text {
    @media (max-width: 680px) {
      display: none;
    }
    font-size: 14px;
    color: #00a344;
    margin-bottom: 0 !important;
    padding: 17px 0 9px 15px;
  }
  .status-icon {
    padding: 15px 0 0 5px;
  }
  .card-header {
    padding: 0 0 0 15px;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none !important;
  }
  .card-text {
    height: 50px;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 40px;

    @include media-breakpoint-only(xs) {
      padding: 5px 40px 5px 10px;
      min-height: 50px;
      height: auto;
    }
  }
  .keyboard-icon {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);

    &:hover {
      cursor: pointer;
    }
  }

  .hok-invalid-status-icon {
    display: inline-block;
    height: 21px;
    width: 21px;
    padding-top: 2px;
    margin-left: 10px;

    @include media-breakpoint-only(xs) {
      height: 19px;
      width: 19px;
      padding: 0;
    }
  }

  .subtitle-text {
    float: left;
    font-size: 16px;
    color: #000;
    padding: 13px 0 6px 15px;
  }

  .mobile-container {
    display: none;
  }

  @media (max-width: 500px) {
    .subtitle-text {
      padding-top: 0 !important;
    }
    .desktop-container {
      display: none;
    }
    .mobile-container {
      display: block;
      height: 45px;
    }
  }

  .show {
    border-top: 1px solid #707070;
  }
  .collapsing {
    border-top: 1px solid #707070;
    width: 100%;
  }
  button {
    max-width: 287px;
  }
}

.hok-assistance-card {
  border: 1px solid #cbccce !important;
  box-shadow: none !important;

  .main-title {
    margin-bottom: 0;
    font-size: 21px;
    letter-spacing: 0;
    color: $text-dark;
    text-transform: uppercase;
    opacity: 1;
    @include media-breakpoint-only(xs) {
      font-size: 18px;
    }
  }
  .main-title-red {
    @extend .main-title;
    color: $primary;
  }

  & > .card-header {
    padding: 0;
    height: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .card-text {
    height: 50px;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 40px;

    @include media-breakpoint-only(xs) {
      padding: 5px 40px 5px 10px;
      min-height: 50px;
      height: auto;
    }
  }
  .keyboard-icon {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);

    &:hover {
      cursor: pointer;
    }
  }

  & > .card-body {
    border-top: 1px solid #707070;
  }
}

.collapsible-package-container {
  @extend .collapsible-text-info-container;
  position: static;
  width: 100%;
  margin-right: 0 !important;

  h3 {
    color: $primary;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;

    &.uppercase {
      text-transform: uppercase;
      font-size: 20px;
    }
  }

  .hok-package-collapse {
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 4px #00000029;
    border: 1px solid #00000029;
    border-top: none;
  }

  .card-header {
    padding: 0;
    border-radius: 10px;
    border: 1px solid $primary;
    background-color: $white !important;
    z-index: 11;
    margin-bottom: -6px;

    .body-text {
      padding: 0 15px;

      @include media-breakpoint-only(xs) {
        padding: 0 0 0 5px;

        h3 {
          font-size: 16px;
        }
      }

      h3.uppercase {
        font-size: 20px;

        @include media-breakpoint-only(lg) {
          font-size: 17px;
        }

        @include media-breakpoint-only(md) {
          font-size: 16px;
        }

        @include media-breakpoint-only(sm) {
          font-size: 18px;
        }

        @include media-breakpoint-only(xs) {
          font-size: 14px;
        }
      }
    }
  }

  .card-body {
    padding: 0;
    border-radius: 0 0 5px 5px;

    .hok-package-data {
      padding: 1.5rem;
    }

    p {
      padding: 0;
      font-size: 16px !important;
    }
  }
  .body-text {
    padding: 0 15px;
  }
  .card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    border: none !important;
    border-radius: 10px !important;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .card-collapsed {
    //@extend .card;
    border-color: #ed1d24;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .submit-container {
    background-color: #f5f5f5;
    height: 70px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price-container {
    background-color: #f5f5f5;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
  }
}

.description-text {
  p {
    padding: 0 !important;
  }
  ul {
    padding-inline-start: 40px;
  }
}

.insurance-package {
  p {
    font-size: 16px;
    padding-left: 0;
    margin: 1rem 0;
    display: flex;

    a {
      font-weight: normal !important;
      color: $primary !important;
    }
  }
  p:last-child {
    border: none;
  }

  hr {
    width: 80%;
    border-top: 1px solid $primary;
  }

  span:not(.placeholder) {
    flex: 1 !important;
  }

  .placeholder {
    @extend .text;
    font-weight: 500;
    text-align: right;
    align-self: flex-end;
    word-break: break-word;
  }

  ul {
    margin: 0 0 1rem 0 !important;
    padding-left: 20px;

    li {
      font-size: 16px !important;
    }

    &.dashed {
      margin: 1rem 0 !important;
      padding-left: 10px;
      & > li {
        //stylelint-disable
        ul {
          margin-top: 0 !important;
        }
        //stylelint-enable
        list-style-type: none;
        text-indent: -10px;
        &::before {
          content: "-";
          margin-right: 5px;
        }
      }
    }
  }

  & > a {
    display: block;
    color: $primary !important;
    font-weight: normal !important;
    text-decoration: underline;
    font-size: 16px;
    margin-top: 2rem !important;
  }

  table {
    margin: 1.5rem -1.5rem -1.5rem -1.5rem;
    width: calc(100% + 2 * 1.5rem);
    background-color: #f5f5f5;

    td {
      border: none;
      padding: 0.5rem;
      vertical-align: middle;
      width: 50%;
      text-align: center;
    }
  }
}

.form-container {
  padding: 25px 15px 25px 15px;
  input {
    font-size: 16px;
  }
  h3 {
    margin-bottom: 1.2rem;
  }
  form {
    margin-top: 20px;
  }
  .yearly-premium {
    h3 {
      margin: 0;
    }
    h2 {
      margin: 0;
    }
  }
  .button-renew {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }
}

.button-submit {
  width: 287px;
  height: 50px;
}

.new-policy {
  .SingleDatePicker {
    width: 100% !important;
  }
  .icon-exit {
    display: none;
    margin-top: 43px;
  }
  @include media-breakpoint-up(md) {
    .icon-exit {
      display: inline-block;
    }
    .icon-exit-sm {
      display: none;
    }
  }

  .insured-people {
    width: 100%;

    // @include media-breakpoint-down(sm) {
    //   .form-group:not(:first-child) {
    //     padding: 2px 0 2px 0;
    //     border-top: 1px solid #f5f5f5;
    //   }
    // }
  }

  @include media-breakpoint-down(sm) {
    label {
      padding-top: 10px;
    }
  }

  .mobile {
    display: none;
    @media (max-width: 700px) {
      width: 100% !important;
      display: block;
    }
    @media (min-width: 480px) {
      width: 75% !important;
    }
  }
  .desktop {
    display: block;
    @media (max-width: 700px) {
      display: none;
    }
  }
  .card-header {
    background-color: $white;
    border-bottom: solid 1px #707070;

    .step-circle-active {
      display: inline-block;
      background-color: #ed1d24;
      width: 35.16px;
      height: 35.16px;
      border-radius: 50%;
    }
    .step-number-active {
      margin: 0;
      margin-top: 4px;
      text-align: center;
      color: #fff;
      font-size: 17px;
    }
    .step-circle-inactive {
      @extend .step-circle-active;
      background-color: #fff;
      border: 1px solid #ed1d24;
    }
    .step-number-inactive {
      @extend .step-number-active;
      color: #ed1d24;
    }
    .step-counter-container > .stepper-container > *:not(:last-child) {
      margin-right: 80px;
      @media (max-width: 480px) {
        margin-right: 20px;
      }
    }
    .step-counter-container,
    .stepper-container {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 560px;
        @media (max-width: 700px) {
          width: 100%;
        }
      }
    }
  }
  .card-body {
    label {
      color: #ed1d24;
    }
  }
  .primary {
    font-size: 20px;
    color: $primary;
    font-weight: bold;
  }
  .secondary {
    font-size: 20px;
    color: #000;
    font-weight: 600;
  }
}

.hok-custom-checkbox {
  top: 490px;
  left: 484px;
  width: 30px;
  height: 30px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 2px solid $primary;
  border-radius: 5px;
  opacity: 1;
  padding-right: 10px;

  .tick {
    padding-left: 5px;
    padding-top: 1px;
  }
}

.custom-header-text {
  color: $text-dark !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.hok-search-result-card {
  border: solid 1px #cbccce !important;
  box-shadow: none !important;
  width: 550px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .card-body {
    padding: 0 4px 0 20px;
    font-size: 16px;
    color: $text-dark;
    min-height: 50px;

    .hok-search-card-container {
      display: inline-flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex: 1;
      flex-wrap: wrap;

      p {
        @include media-breakpoint-up(sm) {
          flex: 1;
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      transform: translate3d(-117px, 40px, 0) !important;
      border: 1px solid $light-grey-color;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      background: $white;
      padding: 10px 0;

      .dropdown-item {
        font-size: 16px;
        border: none;
        color: $text-dark;

        &:active {
          background-color: #f0f3f5;
        }

        &:focus {
          outline: none;
        }
      }
    }

    p {
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0;
      padding: 0;

      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-right: 20px;
      }
    }

    span {
      display: inline-block;
      flex: 1;

      @include media-breakpoint-up(sm) {
        text-align: center;
        flex: none;
        width: 200px;
      }
    }
  }
}

.hok-modal-tac {
  top: 150px;
  .header-container {
    border-bottom: 1px solid #707070;
    padding: 0 1rem;
    min-height: 60px;
  }
  .modal-header {
    border-bottom: none;
    padding: 0;
  }
  .modal-header .modal-title {
    margin: 0 !important;
  }
  h5 {
    font-size: 22px;
    color: #ed1d24;
    font-weight: bold;
  }
  button {
    width: 160px;
    height: 51px;
  }
  @include media-breakpoint-down(xs) {
    top: 50px;
  }
}

.tac-text {
  h3 {
    font-size: 20px;
    color: #000;
    font-weight: bold;
  }
  p {
    font-size: 18px;
  }
}

.hok-tac-checkbox {
  padding-left: 2.5rem;
  display: flex;

  .accept-text-register {
    color: $text-dark;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: -3px;
    flex-wrap: wrap;
  }

  .terms-and-conditions-text-register {
    cursor: pointer;
    text-decoration: underline;
    color: $primary;
    margin-left: 0.25rem;
  }

  .custom-control-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: $text-dark;
    height: 36px;

    .accept-text {
      color: $text-dark;
      font-size: 16px;
      font-weight: 500;
    }

    &::before {
      border: 2px solid $primary;
      height: 30px;
      width: 30px;
      left: -2.5rem;
      // background-color: $white;
    }

    &::after {
      left: -2.5rem;
      height: 30px;
      width: 30px;
      // background-color: $white;
      border: 2px solid $primary;
      border-radius: 0.25rem;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("../img/icons/tick.svg");
  }

  .custom-control-input:not(:disabled):checked ~ .custom-control-label::after {
    background-color: $white;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: unset;
  }
}

.hok-support-icon-label {
  display: inline-block;
  color: $primary !important;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
  margin-left: 1rem;
  text-decoration: none;
}

.label-red {
  label {
    color: $primary !important;
  }
}

.color-green {
  color: #00a344;
}

.color-red {
  color: $primary;
}

.color-warning {
  color: #e9a300;
}

.hok-contact-link {
  color: $text-dark;
  text-decoration: underline;

  &:hover {
    color: $text-dark;
  }
}

.svg-pad-bottom {
  padding-bottom: 4px;
}

.svg-pad-bottom-half {
  padding-bottom: 2px;
}

.svg-table-icons {
  height: 18px;
  width: 18px;
  cursor: pointer;
  outline: none;
}

.svg-white {
  fill: $white;
  & > * {
    fill: $white !important;
  }
}

.svg-menu {
  height: 19px;
  width: 19px;
  padding: 0 0 2px;
  outline: none;
  margin-right: 15px;
  @extend .svg-white;
}

.svg-header-menu {
  height: 20px;
  width: 20px;
  padding: 0 0 4px;
  outline: none;
  margin-right: 10px;
  @extend .svg-text;
}

.svg-red {
  fill: $primary;
  & > * {
    fill: $primary !important;
  }
}

.svg-green {
  fill: #00a344;
  & > * {
    fill: #00a344 !important;
  }
}

.svg-orange {
  fill: #feb100;
  & > * {
    fill: #feb100 !important;
  }
}

.svg-dark {
  fill: $text-dark;
  & > * {
    fill: $text-dark !important;
  }
}

.svg-text {
  fill: $text;
  & > * {
    fill: $text !important;
  }
}

.svg-gray {
  fill: #9f9f9f7f;
  & > * {
    fill: #9f9f9f7f !important;
  }
}

.svg-product-card {
  height: 18px;
  &.svg-warning {
    fill: #e9a300;
    & > * {
      fill: #e9a300 !important;
    }
  }

  &.svg-red {
    @extend .svg-red;
  }

  &.svg-green {
    @extend .svg-green;
  }
}

.svg-input-icon {
  height: 37px;
  width: 18px;
  @extend .svg-text;

  &.svg-red {
    @extend .svg-red;
  }

  &.invalid {
    fill: #c5191e;
    & > * {
      fill: #c5191e !important;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.hok-back-link {
  color: $primary;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #b00e13;
  }
}

.hok-sketch-slider {
  .slick-prev {
    background: url("../img/icons/hok-chevron-left.svg");

    &::before {
      content: "";
    }
  }

  .slick-next {
    background: url("../img/icons/hok-chevron-right.svg");

    &::before {
      content: "";
    }
  }

  .slick-arrow.slick-disabled {
    opacity: 0.2;
  }

  .slick-dots {
    bottom: -50px;

    // stylelint-disable
    li button::before {
      font-size: 10px;
    }

    li.slick-active {
      button::before {
        color: $primary;
      }
    }

    button:hover::before {
      color: $primary;
    }
    // stylelint-enable
  }
}

.hok-mobile-news-preview {
  .hok-news-preview-card-image {
    width: 100%;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hok-news-preview-card-content {
    padding: 15px 30px;

    @include media-breakpoint-down(xs) {
      padding: 15px 16px;
    }

    h2 {
      font-size: 34px;
      color: $primary;
      font-weight: 500;
      margin-bottom: 5px;

      @include media-breakpoint-down(xs) {
        font-size: 24px;
      }
    }

    .hok-news-preview-card-published {
      font-size: 14px;
      color: $text;
      margin-bottom: 0;
    }

    p,
    ul,
    ol {
      font-size: 18px;
      color: $text-dark;

      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }

      a {
        color: $text;
        font-weight: bold;
      }
    }
  }
}

.hok-payment-page {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  color: $text-dark;

  & > div {
    height: 250px;
  }

  img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  .status-img {
    margin-top: 24px;
    width: 100%;
    max-width: 60px;
    height: auto;
  }

  p {
    //color: $text-dark;
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .preloader-text span i {
    display: inline-block;
    width: 11px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.0' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='11px' height='18px' viewBox='70.5 215 11 18' enable-background='new 70.5 215 11 18' xml:space='preserve'%3E%3Cpath fill='%23000000' d='M81.248,224c0,0.145-0.072,0.307-0.18,0.415l-8.405,8.405c-0.108,0.107-0.271,0.18-0.415,0.18 s-0.307-0.072-0.415-0.18l-0.901-0.902c-0.108-0.108-0.181-0.252-0.181-0.415c0-0.144,0.072-0.307,0.181-0.415L78.02,224 l-7.087-7.088c-0.108-0.108-0.181-0.271-0.181-0.415c0-0.145,0.072-0.307,0.181-0.415l0.901-0.902 c0.108-0.108,0.271-0.18,0.415-0.18s0.307,0.072,0.415,0.18l8.405,8.405C81.176,223.694,81.248,223.856,81.248,224z'/%3E%3C/svg%3E");
    margin: 0 10px;
    animation: dotOpacity 1s linear infinite;
    background-repeat: no-repeat;
    background-position: 0 0;

    &:nth-child(1) {
      animation-delay: 100ms;
    }
    &:nth-child(2) {
      animation-delay: 300ms;
    }
    &:nth-child(3) {
      animation-delay: 500ms;
    }

    @keyframes dotOpacity {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.hok-payment-container {
  padding: 24px 18px;
  border-bottom: 1px solid $placeholder;
  max-width: 100%;
  width: fit-content;
  display: inline-block;

  @include media-breakpoint-down(xs) {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(md) {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid $light-grey-color;
    border-radius: 5px;
    box-shadow: 0 2px 4px #00000029;
    margin-top: 0;
  }
}

.hok-payment-cards {
  margin-top: 24px;

  @include media-breakpoint-up(md) {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    margin-left: 40px;
    margin-top: 0;
  }

  & > div {
    &:last-child {
      margin-top: 10px;
    }

    @include media-breakpoint-up(md) {
      min-height: 30px;
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;

      &:last-child {
        margin-top: 0;
      }
    }
  }

  img {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.tooltip.show {
  opacity: 1;
}

.hok-input-tooltip {
  background-color: #d4d4d4;
  color: $text-dark;
  font-size: 12px;
  text-align: left;
  max-width: 240px;
  padding: 10px;
}

.hok-input-tooltip-arrow::before {
  border-top-color: #d4d4d4 !important;
}

@media screen and (max-width: 480px) {
  .user-account-actions {
    display: block !important;
  }

  .user-account-actions > button {
    margin-bottom: 20px !important;
    width: 100% !important;
  }
}

@import "navigation";
@import "dashboard-buttons";

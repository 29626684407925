.hok-modal {
  .modal-header {
    border-bottom: 3px solid $primary;
    height: 50px;
    padding: 10px 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-title {
      margin: 0 auto;
      width: 100%;

      h2 {
        width: 100%;
        height: 100%;
        margin: 0;
        text-align: center;
        font-size: 22px;
        text-decoration: none;
        color: $primary;

        @include media-breakpoint-down(xs) {
          font-size: 18px;
        }
      }
    }
  }

  .modal-body.client {
    .form-group {
      margin-bottom: 0.5rem;
    }

    .invalid-feedback {
      margin-bottom: -0.5rem;
      color: $invalid-color;
    }

    .form-control {
      padding-left: 0;
      padding-right: 0;
    }

    input {
      border: none;
      border-bottom: 1px solid $input-borders;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      color: $input-borders;
      font-size: 16px;

      &::placeholder {
        color: $placeholder;
        opacity: 0.5;
      }
    }

    .input-group-text {
      border: none;
      border-bottom: 1px solid $input-borders;
      border-radius: 0;
      background: none;
      padding-right: 0;
    }

    .input-group-prepend,
    .input-group-append {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $input-borders;
      color: $input-borders;
    }

    .form-control.is-invalid,
    .input-group-prepend.is-invalid,
    .input-group-append.is-invalid {
      background-image: none;
      color: $invalid-color;
      border-color: $invalid-color;

      &:focus {
        box-shadow: none;
      }
    }

    .form-control:focus {
      border-color: $focused-color;
      box-shadow: none;
    }

    .input-group-prepend.focused,
    .input-group-append.focused {
      color: $focused-color;
      border-color: $focused-color;
    }
  }

  .modal-body {
    .hok-fixed-tac-container,
    .hok-fixed-register-tos-container {
      height: 450px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      p {
        font-size: unset;
        color: unset;
        font-weight: unset;
      }
    }

    p {
      font-size: 16px;
      color: $text;
      font-weight: 500;
    }

    .label-text {
      font-size: 20px;
      font-weight: bold;
      color: $primary;
    }

    .modal-text-small {
      font-size: 14px;
    }

    .content-card {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      border: none;

      .card-header {
        height: auto;
      }
    }

    .hok-partners-card {
      border-radius: 10px;
      max-width: 250px;
      border-right: 10px solid transparent;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;

      .card-body {
        padding: 0.75rem;
      }

      h4 {
        font-size: 18px;
        color: $primary;
        text-transform: none;
        font-weight: bold;
      }
    }

    .hok-partners-border-red {
      border-right: 10px solid $primary;
    }
  }

  .modal-footer {
    border-top: none;

    a {
      font-size: 16px;
      color: $text-dark;
      text-decoration: underline;
    }

    img,
    svg {
      margin: 0;
      max-height: 40px;
    }
  }
}

.dashboard-modal {
  .modal-content {
    padding: 8px;
  }

  .modal-header {
    border-bottom: none;
    color: $primary;

    @include media-breakpoint-only(xs) {
      min-height: 50px;
      height: auto;
    }

    .modal-title {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .modal-body {
    @import "dashboard-inputs";

    p {
      color: $text-dark;
    }
  }

  .modal-footer {
    border-top: none;
  }
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 420px;
  }
}

.modal-body,
.modal-footer {
  @import "dashboard-buttons";

  .hok-btn {
    width: 165px;
  }

  .hok-outline-btn {
    width: 165px;
  }
}

.hok-modal-wide {
  .hok-sketch-header {
    .modal-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (min-width: 989px) {
    max-width: 989px;

    .collapsible-package-container {
      width: 410px;
    }
  }
}

.hok-modal-policy {
  top: 70px;
  .modal-header .modal-title {
    margin: 0 !important;
  }
  h5 {
    font-size: 22px;
    color: #ed1d24;
    font-weight: bold;
  }
}

.hok-car-insurance-modal {
  font-size: 16px;
  color: $text-dark;

  .hok-tac-checkbox {
    padding-left: 20px;

    label {
      height: auto;
      line-height: 1.3;
    }
  }

  .small-text {
    font-size: 12px;
  }
}

.maintenance {
  min-height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  h1 {
    color: $primary;
    font-size: 36px;
    margin-bottom: 40px;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      font-size: 28px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }

  h2 {
    font-size: 22px;
    color: $text-dark;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }
  }
}

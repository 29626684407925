// REACT-DATES INPUT STYLED TO LOOK LIKE BOOTSTRAP INPUT
.SingleDatePicker {
  width: 100%;

  .CalendarMonthGrid {
    .CalendarMonth_caption {
      padding-top: 18px;
    }

    select {
      border: 1px solid #e4e7e7;
      color: #484848;
      background: #fff;
      border-radius: 3px;
      height: 33px;
      font-size: 14px;
      width: 85px;
    }
  }

  .SingleDatePickerInput__disabled {
    background: rgba(203, 204, 206, 0.15);

    .DateInput__disabled {
      background: transparent !important;
    }

    .DateInput_input__disabled {
      background-color: unset !important;
    }
  }

  .SingleDatePickerInput {
    width: 100%;
    height: 50px;
    border: 1px solid #cbccce;
    border-radius: 0.25rem;
    display: flex;
    padding-right: 0.35rem;

    .SingleDatePickerInput_calendarIcon {
      //padding-top: 5px;
      //padding-bottom: 5px;
      margin-left: 0;
      display: flex;
      align-items: center;
    }

    .SingleDatePickerInput_clearDate__small {
      line-height: 1.2em;
    }

    .DateInput {
      width: 100%;

      .DateInput_input {
        border-bottom: unset;
        line-height: 20px;
        height: 48px;
        background-color: $white;
      }

      .DateInput_input__small {
        font-size: 0.875rem;
      }
    }
  }
}

.input-group {
  margin-bottom: 1rem;

  & .hok-standard-input {
    border-radius: 0.25rem;
  }
}

label {
  font-size: 16px;
  color: $text;
  font-weight: bold;
  margin-left: 20px;
}

.label-flex {
  min-height: 48px;
  display: flex;
  align-items: flex-end;
}

input,
select,
.PhoneInput {
  border-color: #cbccce;
  height: 50px;

  &.is-invalid {
    border-color: #f86c6b;
  }

  &:disabled,
  &.disabled {
    background: rgba(203, 204, 206, 0.15) !important;
  }
}

select::-ms-expand {
  display: none;
}

input {
  &:read-only {
    background: rgba(203, 204, 206, 0.15);
  }
}

textarea {
  border-color: #cbccce;
  width: 100%;
  resize: none;

  &:disabled,
  &:read-only {
    background: rgba(203, 204, 206, 0.15) !important;
  }
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  color: $text-dark;
  opacity: 0.5;
  font-size: 14px;
}

.input-uppercase {
  text-transform: uppercase;
}

.PhoneInput {
  font-size: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  &.is-invalid {
    border: 1px solid #f86c6b !important;
  }

  &.disabled {
    background: rgba(203, 204, 206, 0.15) !important;
  }

  .PhoneInputCountry {
    height: 100%;
    display: inline-block;
  }

  .PhoneInputInput {
    border: none;
    display: inline-block;
    -webkit-text-fill-color: #5c6873;
    background: transparent !important;
    font-size: 14px;
  }
  .PhoneInputCountrySelectArrow {
    display: inline-block;
  }
  .PhoneInputCountryIcon {
    display: inline-block;

    svg {
      height: 100%;
    }
  }

  .is-invalid {
    border-top: 1px solid #f86c6b !important;
    border-bottom: 1px solid #f86c6b !important;
  }
}

.hok-select {
  width: 100%;
}

.hok-select select {
  //background: transparent;
  height: 50px;
  width: 100%;
  appearance: none;
  background: url("../img/icons/hok-chevron-down.svg") no-repeat;
  background-position-x: calc(100% - 13px);
  background-position-y: center;
  background-size: 20px;
  padding-right: 40px;

  & option {
    color: $text-dark;

    &:disabled {
      color: rgb(170, 170, 170);
    }
  }
}

.hok-multi-select {
  min-height: 50px;

  & input {
    height: unset;
  }
}

// & > .input-group-prepend {
//   background-color: $white;
//   border-top-left-radius: .25rem;
//   border-bottom-left-radius: .25rem;
//   border: 1px solid $light-grey-color;
//   padding-left: 15px;
// }

.hok-search-input {
  padding-left: 50px;
  background-image: url("../img/icons/hok-search.svg") !important;
  background-repeat: no-repeat !important;
  background-position-x: 15px !important;
  background-position-y: center !important;
  background-size: 20px;
}

.hok-upload-select {
  width: 350px;
  height: 36px;
  display: inline-block;
  flex-shrink: 0;

  select {
    height: 35px;
    vertical-align: text-top;
    display: inline-block;
    padding-right: 40px;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
  }
}

.hok-risk-list-container {
  width: 100%;

  hr {
    background-color: $light-grey-color;
  }

  .hok-risk-price {
    display: flex;
    align-items: center;
    text-align: right;

    @include media-breakpoint-down(md) {
      padding-top: 10px;
    }
  }
}

.hok-risks-checkbox-container {
  @extend .hok-risk-list-container;
  max-width: 560px;

  .hok-tac-checkbox {
    .custom-control-label {
      color: $text-dark;
    }

    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: $white;
      border-color: $light-grey-color;
    }

    .custom-control-input:disabled ~ .custom-control-label::after {
      background-color: $white;
      border-color: $light-grey-color;
    }
    .custom-control-input:disabled ~ .custom-control-label span {
      color: $light-grey-color;
      .a {
        fill: $light-grey-color !important;
      }
    }
  }
}

.hok-text-editor {
  &.is-invalid {
    .ql-toolbar {
      border-top-color: #f86c6b;
      border-left-color: #f86c6b;
      border-right-color: #f86c6b;
    }
    .ql-container {
      border-color: #f86c6b;
    }
  }
}
